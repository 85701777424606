export const skillsData = {
    hard:[
        {
            img:'FaReact',
            name:'React',
            type:'Fa',
            id:1
        },
        {
            img:'SiExpress',
            name:'Express',
            type:'Si',
            id:2
        },
        {
            img:'FaNodeJs',
            name:'Node',
            type:'Fa',
            id:3
        },
        {
            img:'SiTypescript',
            name:'Type Script',
            type:'Si',
            id:4
        },
        
        {
            img:'SiMongodb',
            name:'MongoDB',
            type:'Si',
            id:5
        },
        {
            img:'AiOutlineHtml5',
            name:'HTML',
            type:'Ai',
            id:6
        },
        {
            img:'FaCss3',
            name:'CSS',
            type:'Fa',
            id:7
        },
        {
            img:'SiJavascript',
            name:'Java Script',
            type:'Si',
            id:8
        },
        
        
    ],
    tools:[
        {
            img:'FaGitAlt',
            name:'Git',
            type:'Fa',
            id:1
        },
        {
            img:'DiHeroku',
            name:'Heroku',
            type:'Di',
            id:2
        },
        {
            img:'https://img.icons8.com/color/72/visual-studio--v2.png',
            img1:'https://img.icons8.com/ios-filled/2x/visual-studio.png',
            name:'VSCode',
            type:'img',
            id:3
        },
        {
            img:'https://img.icons8.com/nolan/2x/api-settings.png',
            img1:'https://img.icons8.com/carbon-copy/2x/api-settings.png',
            name:'Rest API',
            type:'img',
            id:4
        },
        {
            img:'AiFillGithub',
            name:'Github',
            type:'Ai',
            id:5
        },
        {
            img:'SiChakraui',
            name:'Chakra UI',
            type:'Si',
            id:6
        },
        {
            img:'SiRedux',
            name:'Redux',
            type:'Si',
            id:7
        }
    ]
}

/*

let mouse = {
    x:null,
    y:null
}
canvas1.addEventListener('mousemove', (e)=>{
    mouse.x=e.x;
    mouse.y=e.y;
})
canvas1.addEventListener("mouseleave", ()=>{
    mouse.x=null;
    mouse.y=null;
})

// c1.beginPath()
// c1.arc(350, 350, 99, 0, Math.PI * 2, false);
// c1.strokeStyle = "#000";
// c1.stroke();
// c1.fill()

function Circle(x, y, dx, dy, color) {
    this.x = x;
    this.y = y;
    this.dx = dx;
    this.dy = dy;
    this.color = '#fff'
    this.radius = 5;

    this.draw = function () {
        c1.beginPath()
        c1.arc(this.x, this.y, this.radius, 0, Math.PI * 2, false);
        c1.strokeStyle = '#000'
        c1.stroke();
        c1.fillStyle = this.color
        c1.fill()
    }

    this.update = function (){
        if (this.x + 50 > canvas1.innerWidth || this.x - 50 < 0) {
            this.dx = -this.dx
        }
        if (this.y + 50 > canvas1.innerHeight || this.y - 50 < 0) {
            this.dy = -this.dy
        }
        this.x += this.dx
        this.y += this.dy

        if(Math.abs(this.x - mouse.x) <=50 && Math.abs(this.y - mouse.y) <=50){
            if(this.radius<20){
                this.radius+=1;
                this.color='rgb(108, 5, 112)'
            }
        }else{
            if(this.radius>1){
                this.radius-=0.5
                this.color='white'
            }
        }
        
        this.draw()
    }
}

let circle1 = []

for(let i=0; i<2000; i++){
    let x = Math.random() * canvas1.innerWidth;
    let y = Math.random() * canvas1.innerHeight;
    let dx = (Math.random() -0.5)*2;
    let dy = (Math.random() -0.5)*2;

    if(x<=50){
        x+=50
    }
    if(x>=canvas1.innerWidth-50){
        x-=50
    }
    if(y<=50){
        y+=50
    }
    if(y>=canvas1.innerHeight-50){
        y-=50
    }
    let color;
    if(i%5===1){
        color='skyblue';
    }else if(i%5===2){
        color='red';
    }else if(i%5===3){
        color='blue';
    }else if(i%5===4){
        color='green';
    }else if(i%5===0){
        color='yellow';
    }
    circle1.push(new Circle(x, y, dx,dy, color));
}

function animate(){
    requestAnimationFrame(animate);
    c1.clearRect(0, 0, canvas1.innerWidth, canvas1.innerHeight);

    circle1.forEach((i)=>{
        i.update()
    })

}


animate()
*/