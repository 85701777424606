import GitHubCalendar from 'react-github-calendar'
import { Box, Flex, Heading } from '@chakra-ui/react'
import { Colors } from '../styles/colors';
import {useContext} from 'react'
import {ThemeContext} from '../contexts/ThemeContext';

const style = {
    lvl0: {
        
    },
    lvl1: {
        pt:'75px',
        m:'100px auto',
        w:['100%', '90%','80%'],
        direction:['column'],
        align:'center'
    },
    box1:{
        boxShadow:'lg',
        m:'25px auto',
        p:'35px 35px',
        borderRadius:'20px'
    },
    box2:{
        boxShadow:'lg',
        m:'25px auto',
        p:'25px 35px',
        borderRadius:'20px',
        direction:['column', 'column', 'column', 'row']
        
    },
    heading:{
        mt:['25px', '40px', '55px'],
        pb:'35px',
        className:'silk_screen_font'
    },
    subHeading:{

    }
}

export const GitHub = () => {
    const color = Colors();

    const selectLastHalfYear = contributions => {
        const currentYear = 2022;
        const currentMonth = 12;
        const shownMonths = 6;
      
        return contributions.filter(day => {
          const date = new Date(day.date);
          const monthOfDay = date.getMonth();
      
          return (
            date.getFullYear() === currentYear &&
            monthOfDay > currentMonth - shownMonths &&
            monthOfDay <= currentMonth
          );
        });
      };
      const theme_ = useContext(ThemeContext);
      const {theme} = theme_


    return <Flex {...style.lvl1}>
        
        <Heading {...style.heading} color={color.ch} >GITHUB STATISTICS</Heading>
        <Box {...style.box1} bg={color.bg2}>
            <img src={"http://github-profile-summary-cards.vercel.app/api/cards/profile-details?username=lokeshvyavhare&theme="+(theme?'default':'tokyonight')} alt="."/>
            <GitHubCalendar username="lokeshvyavhare" transformData={selectLastHalfYear} style={{ width: '100%', margin: '25px auto' }}></GitHubCalendar>
        </Box>
        <Flex  {...style.box2} bg={color.bg2}>
        <img src={"http://github-profile-summary-cards.vercel.app/api/cards/stats?username=lokeshvyavhare&theme="+(theme?'default':'tokyonight')} mr='25px'  alt="."/>
        <img src={"http://github-profile-summary-cards.vercel.app/api/cards/productive-time?username=lokeshvyavhare&theme="+(theme?'default':'tokyonight')}  alt="."/>
        </Flex>
    </Flex>
}