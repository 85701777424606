import { Box,
    Heading,
    Text,
    Flex,
    Image
 } from "@chakra-ui/react"
import {useRef } from "react"
import {useDispatch} from 'react-redux';
import { AddRef } from '../Store/Scroll/scroll.action';
import { Canvas } from "./Canvas/Canvas";


const style = {
    lvl0:{
        w:'100%',
        minH:['80vh','90vh'],
        boxSizing:'border-box',
        // background:'radial-gradient(circle farthest-side at bottom right, #220040, #29023f, #37034e, #6c0570)',
        backgroundSize:'100%',
        zIndex:'-2',

        direction:['column', 'column', 'row', 'row']
    },
    lvl1:{
        w:['80%', '70%','40%','40%'],
        h:'fit-content',
        m:'auto'
    },
    textBox:{
        p:['20px 10px', '30px 10px','70px 10px'],
        textAlign:['center', 'center','left'],
        w:['90%','87%','60%'],
        m:'auto',

    },
    head1:{
        size:'xl',
        mb:['25px','35px','75px'],
        className:'silk_screen_font'
    },
    head2:{
        size:'xl',
        pl:['0','0','25px'],
        mb:'40px',
        className:'silk_screen_font'
    },
    head3:{
        size:['3xl', '3xl', '2xl'],
        pl:['0','0','45px','75px'],
        className:'silk_screen_font'
    },
    blurFilm:{
        // backdropFilter: 'blur(1px)',
        position:'absolute',
        w:'100%',
        h:'100%',

    }
}

export const HerOCover = () => {

    const ThisRef = useRef(null);
    const dispatch = useDispatch();
    dispatch(AddRef({key:'heroImageScroll', ref:ThisRef}))

   
    // dispatch(ScrollTo('navScroll'));

    return <Flex {...style.lvl0} ref={ThisRef}>
        <Box display={['none', 'none', 'none', 'none', 'block']}>
        <Canvas />
        </Box>
        <Box  {...style.lvl1}>
            <Image src='https://i.postimg.cc/9f7pN7yC/banner.png' w='100%' h='100%' onScroll={()=>{console.log('e')}}/>

        </Box>
        <Box {...style.textBox}>
            <Text {...style.head1}>#Code&Creativity</Text>
            <Heading {...style.head2}>Hello I'm,</Heading>
            <Heading {...style.head3}>Lokesh Vyavhare</Heading>

        </Box>

    </Flex>
}