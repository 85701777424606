import {
    Box,
    Heading,
    Text,
    Flex,
    Button
} from '@chakra-ui/react';
import resume from '../Media/Lokesh_Vyavhare_Resume.pdf'

import {BsWhatsapp, BsGithub, BsLinkedin} from 'react-icons/bs'
import {MdOutlineMarkEmailRead} from 'react-icons/md'
import { useRef } from 'react'
import { useDispatch } from 'react-redux'
import { AddRef } from '../Store/Scroll/scroll.action'; 
import { Icon } from '@chakra-ui/react'
import { Colors } from '../styles/colors';

const style = {
    heading:{
        mt:['40px', '55px'],
        pb:'35px',
        className:'silk_screen_font'
    },
    lvl0:{
        pb:'35px'

    },
    lvl1:{
        w:['97%','92%','85%','80%'],
        m:'auto',
        p:'25px 35px',
        borderRadius:'25px'
    },
    c1Box:{

    },
    c2Box:{

    }
}


export const Contact = () => {
    const colors = Colors();
    const ThisRef = useRef(null);
    const dispatch = useDispatch();
    dispatch(AddRef({ key: 'ContactScroll', ref: ThisRef }))

    return <Box {...style.lvl0}>

        <Box bg={colors.bg2} {...style.lvl1}>
        <Heading color={colors.cs} {...style.heading} size='xl' ref={ThisRef}>Connect With Me</Heading>
            <Text >Email:- lokeshvyavhare23@gmail.com</Text>
            <Text mb='25px'>Phone:- +91 9405502002</Text>

            <Box display='flex' w='fit-content' m='auto'>
                <a href='https://github.com/LokeshVyavhare' target='_blank'className='onHoverBlack' rel="noreferrer"><Icon as={BsGithub} fontSize={['35px', '45px']} w={['35px', '45px']} /></a>
                <a href='https://wa.me/+919405502002' target='_blank'  className='onHoverBlack' rel="noreferrer"><Icon as={BsWhatsapp} fontSize={['35px', '45px']} w={['35px', '45px']} /></a>
                <a href='https://www.linkedin.com/in/lokesh-vyavhare-b242b2243/'  className='onHoverBlack' target='_blank' rel="noreferrer"><Icon fontSize={['35px', '45px']} w={['35px', '45px']} as={BsLinkedin} /></a>
                <a href='mailto:lokeshvyavhare23@gmail.com' className='onHoverBlack'  target='_blank' rel="noreferrer"><Icon fontSize={['35px', '45px']} w={['35px', '45px']} as={MdOutlineMarkEmailRead}/></a>
            </Box>

        <Heading color={colors.cs} {...style.heading} size='xl'>Get My Resume</Heading>
        <Flex w={['200px', '250px', '300px']} m='auto' className='resume' justify='space-around' align={'center'}>
        <a href="https://drive.google.com/file/d/1sR187q9uOnfAUXoLSPZ5A0OYpbgtLIzq/view?usp=sharing" target='_blank' rel="noreferrer"><Button className='button' bg='#00000022' w={['80px', '110px', '120px']}>View</Button></a>
        <a href={resume} download><Button className='button' bg='#00000022' w={['80px', '110px', '120px']}>Download</Button></a>
        
        </Flex>

        </Box>

    </Box>
}