import { useState } from 'react'
import './Ham.css'
import { ScrollTo } from '../Store/Scroll/scroll.action';
import { useDispatch } from 'react-redux';

export const Ham = ({referal, children}) => {

    const [state, setState] = useState(false);
    const dispatch = useDispatch();

    return <div ref={referal}className={state?"active":''} onClick={()=>{
        if(!state){
            dispatch(ScrollTo('navScroll'));
        }
        setState(!state);
    }}>
        <div  className="ham">
        <div className="hamStick ham1"></div>
        <div className="hamStick ham2"></div>
        <div className="hamStick ham3"></div>
        </div>
        {state? children :''}
    </div>
}