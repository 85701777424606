export const data  = [
    {
        title:"Wanderlust",
        desc:"A full-stack collaborative project with 5 member team. A travel-management full stack website with integrated hotel, flights, user and bookings management system. Complete features related to searching hotels, booking rooms and manage pricing, managing future trips and bookings system",
        image:["https://i.postimg.cc/Gmk7Nz4M/LandingI.png"],
        genStack:["Collaborative", "Travel", "Data-Management"],
        lagStack:[
            {t:"React", i:"https://img.icons8.com/color/2x/react-native.png"},
            {t:"Redux", i:"https://img.icons8.com/color/2x/redux.png"},
            {t:"ExpressJS", i:"https://img.icons8.com/office/2x/express-js.png"},
            {t:"MongoDB", i:"https://img.icons8.com/color/2x/mongodb.png"},
            {t:"Material UI", i:"https://img.icons8.com/color/2x/material-ui.png"},
        ],
        toolStack:[
            {t:'VSCode', i:"https://img.icons8.com/fluency/2x/visual-studio.png"},
            {t:'Git', i:"https://img.icons8.com/color/2x/git.png"}
            
    
    
        ],
        github:"https://github.com/Lokesh777/WonderLust",
        deploy:"https://prized-ring-1889.netlify.app/"
    
    },
    {
        title:"Wardrobe",
        desc:"A collaborative project with 5 member team. A e-commerce full stack website working model with features as searching products by categories, carts, user-management and sample purchase feature.",
        image:["https://i.postimg.cc/SsD9BbrS/Landing-Page.png"],
        genStack:["Collaborative", "E-commerce"],
        lagStack:[
            {t:"React", i:"https://img.icons8.com/color/2x/react-native.png"},
            {t:"Redux", i:"https://img.icons8.com/color/2x/redux.png"},
            {t:"ExpressJS", i:"https://img.icons8.com/office/2x/express-js.png"},
            {t:"MongoDB", i:"https://img.icons8.com/color/2x/mongodb.png"},
            {t:"Chakra UI", i:"https://img.icons8.com/color/2x/chakra-ui.png"},
        ],
        toolStack:[
            {t:'VSCode', i:"https://img.icons8.com/fluency/2x/visual-studio.png"},
            {t:'Git', i:"https://img.icons8.com/color/2x/git.png"}
            


        ],
        github:"https://github.com/LokeshVyavhare/wardrobe",
        deploy:"https://fixed-thread-889.netlify.app/"

    },
    {
        title:"Gym Workout Webapp",
        desc:"A front-end webisite based on Gymwolf.com. Website provides basic advertizing about sevices, various services. A community platform to get to know about various workout plans. A health app to keep track of you health. A list of various exercies. A profile section to maintain your personal data.",
        image:["https://i.postimg.cc/SQntBrPY/Screenshot-137.png", "https://i.postimg.cc/9QdP3RV7/Screenshot-138.png", "https://i.postimg.cc/J7F5jCPG/Screenshot-139.png", "https://i.postimg.cc/rsDG7fFV/Screenshot-140.png","https://i.postimg.cc/5008FszB/Screenshot-141.png","https://i.postimg.cc/KcCsxTXq/Screenshot-142.png", "https://i.postimg.cc/KcCsxTXq/Screenshot-142.png"],
        genStack:["Collaborative", "Service Based"],
        lagStack:[
            {t:"JavaScript", i:"https://img.icons8.com/color/2x/javascript.png"},
            {t:"ES6", i:"https://img.icons8.com/color/2x/javascript.png"},
            {t:"Css", i:"https://img.icons8.com/fluency/2x/css3.png"},
            {t:"HTML5", i:"https://img.icons8.com/color/2x/html-5.png"},
        ],
        toolStack:[
            {t:'VSCode', i:"https://img.icons8.com/fluency/2x/visual-studio.png"},
            {t:'Git', i:"https://img.icons8.com/color/2x/git.png"},
            


        ],
        github:"https://github.com/LokeshVyavhare/Gym-workout-tracker",
        deploy:"https://envious-cactus-6437.netlify.app/"

    }
    // {
    //     title:"Clone of Happyfox.com",
    //     desc:"Project from Construct week, RCT101, Masai. A Individual Project  to clone a website: happyfox https://www.happyfox.com/ . HappyFox is a cloud-based customer relationship management solution that provides help desk and ticketing solutions to businesses across various industries, including computer and software, education, travel and leisure, media and publishing, energy, retail, and more.",
    //     image:["https://i.postimg.cc/k48hLMjY/Screenshot-133.png", "https://i.postimg.cc/25gKHLxB/Screenshot-134.png", "https://i.postimg.cc/Y26s39Lh/Screenshot-135.png", "https://i.postimg.cc/RVvbbBwG/Screenshot-136.png"],
    //     genStack:["Individual", "Service Based", "Server"],
    //     lagStack:[
    //         {t:"React", i:"https://img.icons8.com/officel/2x/react.png"},
    //         {t:"JavaScript", i:"https://img.icons8.com/color/2x/javascript.png"},
    //         {t:"Css", i:"https://img.icons8.com/fluency/2x/css3.png"},
    //         {t:"HTML5", i:"https://img.icons8.com/color/2x/html-5.png"},
    //         {t:"Chakra UI", i:"https://img.icons8.com/color/2x/chakra-ui.png"},
    //     ],
    //     toolStack:[
    //         {t:'VSCode', i:"https://img.icons8.com/fluency/2x/visual-studio.png"},
    //         {t:'Git', i:"https://img.icons8.com/color/2x/git.png"},
    //         {t:'Axios',},
            
            


    //     ],
    //     github:"https://github.com/LokeshVyavhare/omniscient-dust-8885",
    //     deploy:"https://omniscient-dust-8885.netlify.app/"

    // },
    // {
    //     title:"Clone of Campaignmonitor.com",
    //     desc:"A clone Project of service based website campaignmonitor.com. Website mainly features advertising various services provided by the compony. A pricing page with detailed structured information about various subscribtions, a login page and signup page to mark users. Project is individual project.",
    //     image:["https://i.postimg.cc/X7m3Mqx4/Screenshot-144.png", "https://i.postimg.cc/bY6pT3tG/Screenshot-145.png", "https://i.postimg.cc/rFKcYx1f/Screenshot-146.png", "https://i.postimg.cc/rFKcYx1f/Screenshot-147.png"],
    //     genStack:["Individual", "Service Based"],
    //     lagStack:[
    //         {t:"HTML5", i:"https://img.icons8.com/color/2x/html-5.png"},
    //         {t:"Css", i:"https://img.icons8.com/fluency/2x/css3.png"},
    //         {t:"JavaScript", i:"https://img.icons8.com/color/2x/javascript.png"},
    //     ],
    //     toolStack:[
    //         {t:'VSCode', i:"https://img.icons8.com/fluency/2x/visual-studio.png"},
    //         {t:'Git', i:"https://img.icons8.com/color/2x/git.png"},
            


    //     ],
    //     github:"https://github.com/LokeshVyavhare/nifty-silk-2629",
    //     deploy:"https://nifty-silk-2629.netlify.app/"

    // }
]