import { useEffect, useRef, useState } from "react"

export const Canvas = () => {

    const [winDim, setWinDim] = useState({ x: window.innerWidth, y: window.innerHeight });
    const canvas = useRef(null)

    useEffect(() => {
        window.addEventListener('resize', () => {
            setWinDim({ x: window.innerWidth, y: window.innerHeight })
        })
        return window.removeEventListener('resize', () => {
            setWinDim({ x: window.innerWidth, y: window.innerHeight })
        })
    }, [])

    useEffect(() => {
        canvas.current.width = winDim.x
        canvas.current.height = winDim.y
    }, [winDim.x, winDim.y])

    useEffect(() => {
        if(canvas){
            

        let c1 = canvas.current.getContext('2d');

        let mouse = {
            x: null,
            y: null,
            z: false
        }
        canvas.current.addEventListener('mousemove', (e) => {
            mouse.x = e.x;
            mouse.y = e.y;
        })
        canvas.current.addEventListener('click', (e) => {
            mouse.z = true;
            setTimeout(() => {
                mouse.z = false;
            }, 500)
        })
        canvas.current.addEventListener("mouseleave", () => {
            mouse.x = null;
            mouse.y = null;
        })

        // c1.beginPath()
        // c1.arc(350, 350, 99, 0, Math.PI * 2, false);
        // c1.strokeStyle = "#000";
        // c1.stroke();
        // c1.fill()



        function Circle(x, y, dx, dy, color) {
            this.x = x;
            this.y = y;
            this.dx = dx;
            this.dy = dy;
            this.color = '#fff'
            this.radius = 5;

            this.draw = function () {
                c1.beginPath()
                c1.arc(this.x, this.y, this.radius, 0, Math.PI * 2, false);
                c1.strokeStyle = "#00000000"
                c1.stroke();
                c1.fillStyle = this.radius === 1 ? "#00000000" : `rgb(${0},${((this.radius) * 11)}, ${(this.radius) * 17})`
                c1.fill()
            }

            this.update = function () {
                let inc = 50;
                if (mouse.z) {
                    inc = 100
                }
                if (this.x + 15 > winDim.x || this.x - 15 < 0) {
                    this.dx = -this.dx
                }
                if (this.y + 15 > winDim.y || this.y - 15 < 0) {
                    this.dy = -this.dy
                }
                if(this.radius>9){
                    this.x += this.dx
                    this.y += -Math.abs(this.dy)
                }else{
                    this.x += this.dx
                    this.y += this.dy
                }
                

                if (Math.abs(this.x - mouse.x) <= inc && Math.abs(this.y - mouse.y) <= inc) {
                    if (this.radius < 16) {
                        this.radius += 3;
                    }
                } else {
                    if (this.radius > 1) {
                        this.radius -= 1
                    }
                }

                this.draw()
            }
        }

        let circle1 = []

        for (let i = 0; i < 1000; i++) {
            let x = Math.random() * winDim.x;
            let y = Math.random() * winDim.y;
            let dx = (Math.random() - 0.5) * 5;
            let dy = (Math.random() - 0.5) * 5;

            if (x <= 50) {
                x += 50
            }
            if (x >= winDim.x - 50) {
                x -= 50
            }
            if (y <= 50) {
                y += 50
            }
            if (y >= winDim.y - 50) {
                y -= 50
            }
            let color;
            if (i % 5 === 1) {
                color = 'skyblue';
            } else if (i % 5 === 2) {
                color = 'red';
            } else if (i % 5 === 3) {
                color = 'blue';
            } else if (i % 5 === 4) {
                color = 'green';
            } else if (i % 5 === 0) {
                color = 'yellow';
            }
            circle1.push(new Circle(x, y, dx, dy, color));
        }

        function animate() {
            requestAnimationFrame(animate);
            c1.clearRect(0, 0, winDim.x, winDim.y);

            circle1.forEach((i) => {
                i.update()
            })

        }


        animate()
        }
    }, [winDim])

    return <canvas id="canvas" ref={canvas} style={{ backgroundColor: "#00000000" , position:'absolute'}} zindex='2'>

    </canvas>
}